function imageUrlFromResponseData(data) {
  if (data.image_uris) {
    return data.image_uris.normal;
  } else if (data.card_faces) {
    return data.card_faces[0].image_uris.normal;
  } else {
    console.log('No image found in response data', data);
  }
}

function scryfallImageUrlFromCard(card) {
  const cardName = card.getAttribute('data-card-name');
  const mtgSet = card.getAttribute('data-set');
  let imageUrl = `https://api.scryfall.com/cards/named?exact=${encodeURIComponent(cardName)}`;
  if (mtgSet) {
    imageUrl += `&set=${mtgSet}`;
  }
  return imageUrl;
}

export function attachCardNameEventListeners() {
  const cardNames = document.querySelectorAll('.card-name');
  const lightbox = document.getElementById('lightbox');
  const lightboxImg = document.getElementById('lightbox-img');

  const isMobile = 'ontouchstart' in window;

  if (!lightbox || !lightboxImg) {
    console.error('Lightbox or lightbox image element not found');
    return;
  }

  cardNames.forEach(card => {
    if (isMobile) {
      card.addEventListener('click', function () {
        const imageUrl = scryfallImageUrlFromCard(card);

        caches.open('card-images').then(cache => {
          cache.match(imageUrl).then(response => {
            if (response) {
              response.json().then(data => {
                lightboxImg.src = imageUrlFromResponseData(data);
                lightbox.style.display = 'grid';
              });
            } else {
              fetch(imageUrl)
                .then(response => {
                  cache.put(imageUrl, response.clone());
                  return response.json();
                })
                .then(data => {
                  lightboxImg.src = imageUrlFromResponseData(data);
                  lightbox.style.display = 'grid';
                });
            }
          });
        });
      });
    } else {
      if (card.tagName === 'IMG') {
        return;
      }

      card.addEventListener('mouseover', function (event) {
        const imageUrl = scryfallImageUrlFromCard(card);

        caches.open('card-images').then(cache => {
          cache.match(imageUrl).then(response => {
            if (response) {
              response.json().then(data => {
                card.style.setProperty('--card-image', `url(${imageUrlFromResponseData(data)})`);
                createHoverImage(card, event);
              });
            } else {
              fetch(imageUrl)
                .then(response => {
                  cache.put(imageUrl, response.clone());
                  return response.json();
                })
                .then(data => {
                  card.style.setProperty('--card-image', `url(${imageUrlFromResponseData(data)})`);
                  createHoverImage(card, event);
                });
            }
          });
        });
      });

      card.addEventListener('mouseout', function () {
        const hoverImg = document.querySelector('.hover-image');
        if (hoverImg) {
          hoverImg.remove();
        }
      });
    }
  });

  lightbox.addEventListener('click', function () {
    lightbox.style.display = 'none';
  });

  function createHoverImage(card, event) {
    function onMouseMove(event) {
      let hoverImage = document.querySelector('.hover-image');
      if (!hoverImage) {
        hoverImage = document.createElement('img');
        hoverImage.className = 'hover-image';
        hoverImage.style.position = 'absolute';
        hoverImage.style.pointerEvents = 'none';
        hoverImage.style.zIndex = '1000';
        hoverImage.style.display = 'none';
        hoverImage.onload = function() {
          hoverImage.style.display = 'block';
        };
        document.body.appendChild(hoverImage);
      }
      hoverImage.src = card.style.getPropertyValue('--card-image').slice(4, -1).replace(/"/g, "");

      const hoverImageWidth = hoverImage.offsetWidth || 240;
      const hoverImageHeight = hoverImage.offsetHeight || 336;

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      let left = event.pageX + 10;
      let top = event.pageY + 10;

      if (left + hoverImageWidth > windowWidth + window.scrollX) {
        left = event.pageX - hoverImageWidth - 10;
      }

      if (top + hoverImageHeight > windowHeight + window.scrollY) {
        top = event.pageY - hoverImageHeight - 10;
      }

      hoverImage.style.left = `${left}px`;
      hoverImage.style.top = `${top}px`;
    }

    // trigger once to show image even when user doesn't move mouse
    onMouseMove(event);
    card.addEventListener('mousemove', onMouseMove);
  }
}
